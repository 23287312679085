import './EventCard.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceIcon from '@mui/icons-material/Place';

interface EventCardProps {
    title: String;
    description: String | null;
    start: Date;
    end: Date;
    location: String | null;

    imgSrc: String | null;

    loaded: Boolean | null;
};

export default function EventCard(props: EventCardProps) {
    const loaded = props.loaded || props.loaded === null;

    const start = new Date(props.start);
    const end = new Date(props.end);

    const day = start.getDate();
    const monthText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][start.getMonth()];

    return (
        <div className='event-card'>
            <div className='date-container'>
                <p className='day'>{day}</p>
                <p className='month'>{monthText}</p>
            </div>
            <img src={props.imgSrc?.toString() || 'https://via.placeholder.com/150'} alt='Event' />
            <div className='description'>
                <h1>{props.title}</h1>
                <div className='sub-details'>
                    <div className='sub-detail'>
                        <AccessTimeIcon />
                        <p>{start.toLocaleTimeString().slice(0, -6)} - {end.toLocaleTimeString().slice(0, -6)}</p>
                    </div>
                    <div className='sub-detail'>
                        <PlaceIcon />
                        <p>{props.location || 'None'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}