import './GoogleForm.css';
import CloseIcon from '@mui/icons-material/Close';

export default function GoogleForm(props: {src: string}) {
    const handleClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            document.querySelector('.click-catcher')?.remove();
        }
    }

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            document.querySelector('.click-catcher')?.remove();
        }
    });

    return (
        <div className='click-catcher' onClick={handleClick}>
            <div className='google-form-container'>
                <div className='window-bar'>
                    <CloseIcon onClick={handleClick} />
                </div>
                <iframe src={props.src} className='google-form'></iframe>
            </div>
        </div>
    );
}