import './SupportPage.css';
import FundrasingPicture from './images/fundraising-new.jpeg';
import VolunteerPicture from './images/volunteer-new.jpeg';

export default function SupportPage() {
    return <div className='support-page'>
        <h1 className='title'>Support Us</h1>
        <div className='sections'>
            <div className='section section-volunteer'>
                <div className='info-section'>
                    <div className='content'>
                        <img src={VolunteerPicture} alt='placeholder' />
                        <div className='description'>
                            <h2>Spark Change, Be a Ruby <a style={{color: "var(--color-primary)", fontWeight: "750"}}>Volunteer</a></h2>
                            <p>Volunteering is a great way to give back to the community. 
                                We are always looking for volunteers to help us with our mission. 
                                If you are interested in volunteering, please contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section section-fundrasing'>
                <div className='content'>
                    <h2>Join us at our <a style={{color: "var(--color-primary)", fontWeight: "750"}}>Fundraising</a> events</h2>
                    <p>We had a successful first year with our fundraising dinner. 
                        We were to raise a 1/3 of our operating cost last year. We have big plans this 
                        year and are looking forward to adding more fundraising events. On top of having a 
                        fundrasing dinner on April 29th, will will be having a golf outing on June 24th. 
                        We will  also be hosting a carnival event later this year with date TBD.
                    </p>
                </div>
                <img src={FundrasingPicture} alt='placeholder' />
            </div>
            <div className='section section-donate'>
                <div className='content'>
                    <p>Or</p>
                    <h2>Ways to <a style={{color: "var(--color-primary)", fontWeight: "750", marginRight: "150px"}}>donate</a></h2>
                    {/* <p>Some text about donating here Some text about donating here Some text about donating here Some text about donating here Some text about donating here</p> */}
                </div>
                <ul>
                    <li onClick={() => window.open('https://www.paypal.com/donate/buttons', '_blank')} style={{cursor: 'pointer'}}><a style={{color: 'black'}} href='https://www.paypal.com/donate/buttons'>Paypal</a></li>
                    <li onClick={() => window.open('https://account.venmo.com/u/bearuby0319', '_blank')} style={{cursor: 'pointer'}}><a style={{color: 'black'}} href='https://account.venmo.com/u/bearuby0319'>Venmo</a></li>
                    <li onClick={() => window.open('https://be-a-ruby.square.site/', '_blank')} style={{cursor: 'pointer'}}><a style={{color: 'black'}} href='https://be-a-ruby.square.site/'>Shop</a></li>
                    <li onClick={() => window.open('https://www.amazon.com/hz/wishlist/ls/YTJ0L08ELEV3?ref_=wl_share', '_blank')} style={{cursor: 'pointer'}}><a style={{color: 'black'}} href='https://www.amazon.com/hz/wishlist/ls/YTJ0L08ELEV3?ref_=wl_share'>Amazon List</a></li>
                </ul>
            </div>
        </div>

    </div>
}