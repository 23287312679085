import GoogleForm from '../../components/google-form/GoogleForm';
import React from 'react';
import ReactDOM from 'react-dom';
import './EventsPage.css';
import { createRoot } from 'react-dom/client';
import Appender from '../../Appender';

function createAndAppend() {
    // <GoogleForm src="https://docs.google.com/forms/d/e/1FAIpQLSefJlZjs44Fp06oTuEABZIAqDUYgaZkrV67ATHHguVqn6nQdQ/viewform?embedded=true" />
    const component = <GoogleForm src="https://docs.google.com/forms/d/e/1FAIpQLSefJlZjs44Fp06oTuEABZIAqDUYgaZkrV67ATHHguVqn6nQdQ/viewform?embedded=true" />;
    Appender.instance.addComponent(component);
}

export default function EventsPage() {
    return (
        <div className='events-page'>
            <h1>Our Events</h1>
            <button onClick={createAndAppend}>Signup to Volunteer!</button>
            <iframe src="https://embed.styledcalendar.com/#dVcPs70VePnbZfQWfO72" title="Styled Calendar" className="styled-calendar-container" data-cy="calendar-embed-iframe"></iframe>
            {/* <GoogleForm src="https://docs.google.com/forms/d/e/1FAIpQLSefJlZjs44Fp06oTuEABZIAqDUYgaZkrV67ATHHguVqn6nQdQ/viewform?embedded=true" /> */}

        </div>
    );
}