import NavBar from './components/navbar/NavBar';
import './Colors.css';
import HomePage from './pages/home/HomePage';
import Router from './pages/Router';
import SupportPage from './pages/support-us/SupportPage';
import InfoFooter from './pages/info-footer/InfoFooter';
import WhoWeAre from './pages/who-we-are/WhoWeAre';
import EventsPage from './pages/events-page/EventsPage';
import BackToTopButton from './components/back-to-top-button/BackToTopButton';

const routes = [
  {path: '/', component: HomePage},
  {path: '/support-us', component: SupportPage},
  {path: '/who-we-are', component: WhoWeAre},
  {path: '/events', component: EventsPage}
];

function App() {
  return (
    <div className="app">
      <NavBar />
      <Router items={routes} />
      <InfoFooter />
      <BackToTopButton />

    </div>
  );
}


export default App;