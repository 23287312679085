import LandingSection from "./sections/landing/LandingSection"
import EventsSection from "./sections/events/EventsSection"
import OurTeamSection from "./sections/ourteam/OurTeamSection"

export default function HomePage() {
    return <div className="home-page">
        < LandingSection />
        <EventsSection />
        <OurTeamSection />
    </div>
}