import React from "react";

export default class Appender extends React.Component {
    static instance: Appender;
    static components: any[] = [];

    constructor(props: any) {
        super(props);
        Appender.instance = this;
    }

    addComponent(component: any) {
        Appender.components.push(component);
        this.forceUpdate();
    }

    removeComponent(component: any) {
        const index = Appender.components.indexOf(component);
        if (index !== -1) {
            Appender.components.splice(index, 1);
            this.forceUpdate();
        }
    }

    clearComponents() {
        Appender.components = [];
        this.forceUpdate();
    }

    render() {
        return Appender.components;
    }
}
