import React from 'react';

export default class Router extends React.Component<RouterProps, { activeIndex: number }> {
    static router: Router | null = null;

    constructor(props: RouterProps) {
        super(props);

        // look for the path in the URL
        const path = window.location.pathname;
        const index = this.props.items.findIndex(item => item.path === path);
        
        this.state = {
            activeIndex: index === -1 ? 0 : index
        };

        this.setActiveIndex = this.setActiveIndex.bind(this);
        Router.router = this;
    }

    static getRouter(): Router {
        if (Router.router === null) {
            throw new Error('Router is not initialized');
        }

        return Router.router;
    }

    setActiveIndex(index: number) {
        window.scrollTo({top: 0, behavior: 'auto'});

        this.setState({
            activeIndex: index
        });

        const path = this.props.items[index].path;
        window.history.pushState(null, '', path);
    }

    setActivePath(path: string) {
        const index = this.props.items.findIndex(item => item.path === path);
        if (index === -1) {
            window.location.href = '/'
        }

        this.setActiveIndex(index);
    }

    render() {
        return (
            <div>
                {this.props.items.map((item, index) => (
                    <Route
                        key={index}
                        path={item.path}
                        component={item.component}
                        active={this.state.activeIndex === index}
                    />
                ))}
            </div>
        );
    }
}

export interface RouterProps {
    items: { path: string, component: React.ComponentType }[];
}

interface RouteProps {
    path: string;
    component: React.ComponentType;
    active?: boolean;
}

function Route(props: RouteProps) {
    return (
        <div className='routed-item' style={{'display': props.active ? 'block' : 'none'}}>
            {props.active && <props.component />}
        </div>
    );
}