import React from 'react';
import './NavBar.css';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from '../../pages/Router';

interface NavItemProps {
  callback?: () => void;
  text: string;
}

function NavItem(props: NavItemProps) {
    return (
        <li className='nav-item'>
            <a onClick={() => props.callback && props.callback()} style={{cursor: props.callback ? 'pointer' : 'default', color: props.callback ? 'white' : 'white'}}>
                {props.text}
            </a>
        </li>
    );
}

function VertNavItem(props: NavItemProps) {
    return (
        <li className='v-nav-item'>
            <a onClick={() => props.callback && props.callback()} style={{cursor: props.callback ? 'pointer' : 'default', color: props.callback ? 'white' : 'white'}}>
                {props.text}
            </a>
        </li>
    );
}

export default class NavBar extends React.Component {
  constructor(props: any) {
    super(props)
  }

  hideVertNav = () => {
    document.querySelector('.vert-nav-bar')?.classList.remove('show');
  }

  // vertNavToggle = () => {
  //   document.querySelector('.vert-nav-bar')?.classList.toggle('show');
  // }

  // 1second debounce
  vertNavToggle = () => {
    let lastClick = 0;
    if (Date.now() - lastClick > 1000) {
      document.querySelector('.vert-nav-bar')?.classList.toggle('show');
      lastClick = Date.now();
    }
  }

  render() {
    return (
      <nav className="navbar">
        <ul className='horiz-nav-bar'>
            <div className='home-button' onClick={() => {Router.getRouter().setActivePath('/')}}>
                  <FontAwesomeIcon icon={faHeart} />
                  <a>Be Kind LoveBig</a>
            </div>

            <NavItem callback={() => {Router.getRouter().setActivePath('/')}} text="Home" />
            <NavItem callback={() => {Router.getRouter().setActivePath('/who-we-are')}} text="Who We Are" />
            <NavItem callback={() => {Router.getRouter().setActivePath('/support-us')}} text="Support Us" />
            <NavItem callback={() => {Router.getRouter().setActivePath('/events')}} text="Events" />
            <NavItem callback={() => {window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'})}} text="Contact Us" />

            {/* For small displays */}
            <li className='vert-nav-item'>
              <a onClick={this.vertNavToggle} style={{cursor: 'pointer', color: 'white'}}>
                <FontAwesomeIcon icon={faBars} />
              </a>
            </li>
        </ul>

        <ul className='vert-nav-bar'>

            <VertNavItem callback={() => {Router.getRouter().setActivePath('/'); this.hideVertNav()}} text="Home" />
            <VertNavItem callback={() => {Router.getRouter().setActivePath('/who-we-are'); this.hideVertNav()}} text="Who We Are" />
            <VertNavItem callback={() => {Router.getRouter().setActivePath('/support-us'); this.hideVertNav()}} text="Support Us" />
            <VertNavItem callback={() => {Router.getRouter().setActivePath('/events'); this.hideVertNav()}} text="Events" />
            <VertNavItem callback={() => {window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'}); this.hideVertNav()}} text="Contact Us" />
        </ul>
      </nav>
    );
  }
}