import './BackToTopButton.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



export default function BackToTopButton() {
    // recursively check if it's a child of the element
    var isChildOf = (child: HTMLElement | null, parent: HTMLElement): boolean => {
        if (child === parent) return true;
        if (child === null) return false;
        return isChildOf(child.parentElement, parent);
    }

    document.addEventListener('scroll', () => {
        const button = document.querySelector('.back-to-top-button') as HTMLElement;
        if (window.scrollY > 500) {
            button.style.display = 'block';
        } else {
            button.style.display = 'none';
        }
    });

    document.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains('back-to-top-button') || isChildOf(target, document.querySelector('.back-to-top-button') as HTMLElement)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    });

    return (
        <div className="back-to-top-button">
            <ArrowUpwardIcon />
        </div>
    )
}