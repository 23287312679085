import Router from '../Router'
import './WhoWeAre.css'
import Family from './images/Family.jpg'
import Ruby from './images/Ruby.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import TeamMemberCard from '../../components/team-member-card/TeamMemberCard'

import HarryRissley from '../../people-images/Harry.jpeg'
import Lady1 from '../../people-images/Messenger_creation_5e59590c-be29-4ddf-9bc5-872efa639fbd.jpeg'
import Person2 from '../../people-images/Messenger_creation_470767e1-e1c3-4d9c-b4d4-e070946a480b.jpeg'
import Person3 from '../../people-images/Messenger_creation_77f7c954-008c-4794-9e65-795b07e1afc8.jpeg'
import Person4 from '../../people-images/Messenger_creation_81fabc6a-01f7-4c64-a4c0-2d98bcf3c8d4.jpeg'
import Person5 from '../../people-images/Messenger_creation_8eaf06df-4415-47c6-a897-a6bf9240ff57.jpeg'


export default function WhoWeAre() {
    return <div className='who-we-are'>
        <div className='main-body'>
            <div className='section'>
                <h1>Who We Are</h1>
                <div className='sub-section'>
                    <p>We are the Rissley’s, Harry, Miranda, Harry IV, Ruby, Leo, and Finn. We are your typical fun, outgoing family. We love camping and spending time outdoors with friends and family.  When we are not outdoors, we love playing boardgames and cooking. On February 27, 2021, our lives changed forever when we lost our little girl. On February 25th she was on her way home from school when she was hit by a car and rushed to the hospital. She passed two days later from the injuries.</p>

                    <p>In honor of her we started the Be a Ruby non-profit to do the work she did her whole life. Even at the young age of 7 she was an amazing person when it came to having a heart of gold. To Ruby everyone was her friend. She loved everyone and in everything she did, she did it with a smile and love. No matter how you felt that day, if you spent a few minutes with her, you would be smiling. She loved God and everything that went with it. She loved singing his songs and dancing to the music. She lived and is the reason of Be Kind Love Big.</p>

                    <p>#BEARUBY</p>
                </div>

                <div className='images'>
                    <img src={Ruby} alt='Ruby' />
                    <img src={Family} alt='Family' />
                </div>
            </div>
            {/* <button onClick={() => {Router.getRouter().setActivePath('/support-us')}}>Support Us</button> */}

            <div className='section'>
                <h1>Our Team</h1>
                <a id='team' style={{position: 'relative', top: '-100px'}}></a>
                <div className='team-display'>
                    <TeamMemberCard name='Harry Rissley III' title='Director' imgSrc={HarryRissley} />
                    <TeamMemberCard name='Sue Isenhoff' title='Treasurer' imgSrc={Lady1} />
                    <TeamMemberCard name='Taylor Borrello' title='Secretary' imgSrc='https://via.placeholder.com/150' />
                    <TeamMemberCard name='Luke Treece' title='' imgSrc={Person2} />
                    <TeamMemberCard name='Garry VanDenBerg' title='' imgSrc={Person3} />
                    <TeamMemberCard name='Donovan Gardner' title='' imgSrc={Person4} />
                    <TeamMemberCard name='Harry Rissley Jr.' title='' imgSrc={Person5} />
                    {/* <TeamMemberCard name='Jane Smith' title='CTO' imgSrc='https://via.placeholder.com/150' /> */}
                </div>
            </div>
        </div>
    </div>
}