import TeamMemberCard from '../../../../components/team-member-card/TeamMemberCard'
import Router from '../../../Router'
import './OurTeamSection.css'
import HarryRissley from '../../../../people-images/Harry.jpeg'
import Lady1 from '../../../../people-images/Messenger_creation_5e59590c-be29-4ddf-9bc5-872efa639fbd.jpeg'



export default function OurTeamSection() {
    return <div className='our-team-section'>
        <div className='content-container'>
            <div className='header-text'>
                <h1>Our Team</h1>
                <p>At the heart of Be Kind, Love Big is a team of dedicated and skilled leaders, 
                    each passionate about fostering a culture of compassion and generosity. With a diverse range 
                    of talents and experiences, our leadership brings a wealth of expertise to drive the organization's 
                    mission forward.
                </p>
            </div>
            <div className='member-container'>
                <TeamMemberCard name='Harry Rissley III' title='Director' imgSrc={HarryRissley} />
                <TeamMemberCard name='Sue Isenhoff' title='Treasurer' imgSrc={Lady1} />
                <TeamMemberCard name='Taylor Borrello' title='Secretary' imgSrc='https://via.placeholder.com/150' />
            </div>

            <button className='view-more-button' onClick={() => {
                Router.getRouter().setActivePath('/who-we-are')
                // scroll to the #team section
                setTimeout(() => {
                    const teamSection = document.getElementById('team')
                    if (teamSection) {
                        // cancel current scroll
                        teamSection.scrollIntoView({behavior: 'smooth'})
                    } else {
                        alert('Team section not found')
                    }
                }, 100)
            }}>View More</button>
        </div>
    </div>
}