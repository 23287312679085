import './TeamMemberCard.css';

interface TeamMemberCardProps {
    name: string;
    title: string;
    imgSrc?: string;
    img?: React.ReactNode;
}

export default function TeamMemberCard({ name, title, imgSrc, img }: TeamMemberCardProps) {
    if (imgSrc) {
        img = <img src={imgSrc} alt={name} />
    }
    
    return (
        <div className='team-member-card'>
            {img}

            <div className='name-plate'>
                <div className='text-display'>
                    <p>{name}</p>
                    <a>{title}</a>
                </div>
            </div>
        </div>
    )
}