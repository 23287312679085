import React from 'react';
import EventCard from '../../../../components/event-card/EventCard';
import Router from '../../../Router';
import './EventsSection.css';
import ICAL from 'ical.js'

// Need to figure out if each event will have a image associated with it

const ICAL_URL = 'https://corsproxy.io/?' + encodeURIComponent("https://calendar.google.com/calendar/ical/0ed4c4986123e36e0d7f13b7ed0e7aac7bb7578b8be2487fc707406e695df563%40group.calendar.google.com/public/basic.ics");
var CALENDAR_EVENTS: Event[] | null = null;


if (localStorage.getItem('events-time') !== null) {
    const time = parseInt(localStorage.getItem('events-time') as string);
    if (Date.now() - time > 30 * 60 * 1000) { // 30 minutes
        localStorage.removeItem('events');
        localStorage.removeItem('events-time');
    } else {
        CALENDAR_EVENTS = JSON.parse(localStorage.getItem('events') as string);
    }
}

if (CALENDAR_EVENTS === null || CALENDAR_EVENTS.length === 0) {
    fetch(ICAL_URL).then(response => {return response.text()}).then(data => {
        CALENDAR_EVENTS = parseEvents(data);
        CALENDAR_EVENTS = CALENDAR_EVENTS.filter(event => event.end > new Date()).sort((a, b) => a.start.getTime() - b.start.getTime());
        console.log(CALENDAR_EVENTS);
        /*
            ;

            add this back in after populating the google calendar with events
        */

        // cache the results
        localStorage.setItem('events', JSON.stringify(CALENDAR_EVENTS));
        localStorage.setItem('events-time', Date.now().toString());
        
        EventsSection.components.forEach(component => {
            component.forceUpdate();
        });
    });

}


export default class EventsSection extends React.Component {
    static components: EventsSection[] = [];

    constructor(props: any) {
        super(props);
        EventsSection.components.push(this);
    }

    render() {
        return <div className='events-section'>
            <h1>Events</h1>
            <div className='events-container'>
                {CALENDAR_EVENTS === null ? <p>Loading...</p> : CALENDAR_EVENTS.slice(0, 3).map((event, index) => {
                    return <EventCard title={event.title} description={event.description} start={event.start} end={event.end} location={event.location} imgSrc={"https://via.placeholder.com/150"} loaded={true} key={index} />
                })}
            </div>

            <button className='view-all' onClick={() => {Router.getRouter().setActivePath('/events')}}>View Full Calendar</button>
        </div>
    }
}

interface Event {
    title: string;
    location: string;
    description: string;
    start: Date;
    end: Date;
}

function parseEvents(ics: string): Event[] {
    const events: Event[] = [];
    
    const data = ICAL.parse(ics);
    const comp = new ICAL.Component(data);
    const vevents = comp.getAllSubcomponents('vevent');

    for (const vevent of vevents) {
        const event = {
            title: vevent.getFirstPropertyValue('summary'),
            location: vevent.getFirstPropertyValue('location'),
            description: vevent.getFirstPropertyValue('description'),
            start: vevent.getFirstPropertyValue('dtstart').toJSDate(),
            end: vevent.getFirstPropertyValue('dtend').toJSDate()
        };

        events.push(event);
    }

    return events;
}
