import './InfoFooter.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ContactOptionProps {
    title: String;
    value: String;
    valueHref: String;
    icon: JSX.Element;
};

function ContactOption(props: ContactOptionProps) {
    return <li className="contact-option">
        {props.icon}
        <div className="contact-info">
            <h2>{props.title}</h2>
            <a href={props.valueHref.toString()} target='_blank'>{props.value}</a>
        </div>
    </li>
}

export default function InfoFooter() {
    return <div className='info-footer'>
        <div className="col col-3">
            <h1>CONNECT WITH US</h1>
            <ul>
                <ContactOption title="Check us out on" value="Facebook" valueHref="https://www.facebook.com/beaRuby.BeKind.LoveBig" icon={<FacebookIcon />} />
                <ContactOption title="Find us on" value="Instagram" valueHref="https://www.instagram.com/be.a.ruby" icon={<InstagramIcon />} />
                <ContactOption title="Also find us on" value="TikTok" valueHref="https://www.tiktok.com/@be_aruby" icon={<FontAwesomeIcon icon={faTiktok} />} />
            </ul>
        </div>

        <div className="col col-3">
            <h1>GET IN TOUCH</h1>
            <ul>
                <ContactOption title="Vist Us" value="2976 Ivanrest Ave SW" valueHref="https://maps.app.goo.gl/5PEL1LFKdcWCSLzB9" icon={<PlaceIcon />} />
                <ContactOption title="Call us" value="+1-616-370-3722" valueHref="tel:616-370-3722" icon={<PhoneIcon />} />
                <ContactOption title="Email Us" value="hrissley@bearuby.org" valueHref="mailto:hrissley@bearuby.org" icon={<EmailIcon />} />
            </ul>
        </div>
    </div>
};